
const API={
    getcode:"/auth/code",//获取验证码
    login:"/auth/login",//登录
    logout:"/auth/logout",//登出
    usermenu:"/api/menus/build",//获取用户菜单权限
    users:"/api/users",//用户
    order:"/api/order",//代付订单
    repayment:"/api/repayment/order",//代收订单
    accountBook:"/api/apply",//应用管理
    coverCharge:"/api/cover/charge",//服务配置
    channel:"/api/channel",//通道
    safe:"/api/safe",//安全
    stochastic:"/api/safe/stochastic",//安全随机
    essential:"/api/essential",//基本信息
    bankType:"/api/bank/bankType",//银行类型
    bank:"/api/bank",//银行信息
    mencionar:"/api/mencionar",//提现管理
    capital:"/api/capital",//资金
    withdraw:"/api/capital/withdraw",//提现
    auditoria:"/api/capital/auditoria",//审核
    cobrador:"/api/capital/cobrador",//收款
    updateBuzon:"/api/users/updateBuzon",//修改邮箱
    authPay:"/api/repayment/order/authPay",//放款审核
    download:"/api/order/download",//代付订单数据导出
    download1:"/api/repayment/order/download",//代收订单数据导出
    sevenDays:"/api/home/sevenDays",//代收订单数据导出
    mchData:"/api/home/mchData",//商户数据统计
    channelData:"/api/home/channelData",//渠道数据统计
    summary:"/api/home/summary",//汇总统计
    sevenSummary:"/api/home/sevenSummary",//时间查询汇总统计
    totalData:"/api/home/totalData",//汇总统计
    curso:"/api/order/curso",//资金流水
    cursoDownload:"/api/order/cursoDownload",//导出资金流水
    ranking:"/api/home/ranking",//商户排行榜
    refund:"/api/capital/refund",//退款
    monthly:"/api/home/monthly",//每月数据统计
    manualLoan:"/api/capital/manualLoan",//放款
    reject:"/api/capital/reject",//驳回
    channellist:"/api/cover/charge/channelList",//渠道列表
    nationlist:"/api/cover/charge/nationList",//国家列表
    channelsList:"/api/cover/charge/channelsList",//渠道列表
}

export default API