
const API={
    perm:"/api/menus",//权限接口
    permlazy:"/api/menus/lazy",//返回所有菜单
    roles:"/api/roles",//角色
    roleslist:"/api/roles/all",//角色列表
    menu:"/api/roles/menu",//保存菜单
    users:"/api/users",//用户
    cards:"/api/hogares/cards",//用户
    updatePass:"/api/users/updatePass",//修改密码
    resetPass:"/api/users/resetPass",//重置密码
    logs:"/api/logs",//修改密码
    delIpLock:"/api/users/delIpLock",//解锁
    updatePayPass:"/api/users/updatePayPass",//修改支付密码
    mencionar:"/api/mencionar/recargar",//商户充值
    hogares:"/api/hogares",//公户管理
    bankType:"/api/bank/bankType",//银行类型
    clabePrefectura:"/api/clabe/prefectura",//clabe号段分配
    payType:"/api/clabe/prefectura/payType",//配置放款渠道
    addIp:"/api/clabe/prefectura/addIp",//配置ip
    nationlist:"/api/cover/charge/nationList"//国家列表
}

export default API